import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Routes } from '../constants/Routes';
import SEO from '../components/Seo';
import Title from '../components/title';
import YouTube from 'react-youtube';
import useWindowSize from '../hooks/useWindowSize';
import Icon from '../components/Icon';
import didYouMean from 'didyoumean2';
import { Link } from '@reach/router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Post = ({ data: { allSitePage, contentfulTeam } }) => {
  const { width } = useWindowSize();
  const ref = useRef<HTMLDivElement>();
  const [columnWidth, setColumnWidth] = useState(800);
  const paths: string[] = allSitePage.edges.map(({ node }) => node.path);

  const findCourse = (stil: string) => {
    const res = didYouMean(stil, paths);
    if (res) {
      if (Array.isArray(res)) {
        return res[0];
      } else {
        return res;
      }
    }
    return Routes.ERWACHSENE;
  };

  useEffect(() => {
    if (ref.current) {
      setColumnWidth(ref.current.clientWidth);
    }
  }, [ref.current]);

  return (
    <Layout location={Routes.TEAM}>
      <SEO title={contentfulTeam.name} />
      {width && (
        <div className="mt-8 md:mt-0 relative">
          <Link to={Routes.TEAM}>
            <Icon className="h-16 w-16 -ml-2 md:w-24 md:h-24 md:ml-4" name="arrow_left" />
          </Link>
          <Title className="md:mt-0 mb-4 text-center">
            {contentfulTeam.name === 'Sanna Kurtenbach' || contentfulTeam.name === 'Ana Tajouiti'
              ? `${contentfulTeam.name} (Schulleitung)`
              : contentfulTeam.name}
          </Title>
          <div className="mt-8 transition-all transition-250 shadow-lg hover:shadow-xl flex flex-col items-center lg:flex-row lg:w-11/12 lg:mx-auto lg:items-start max-w-6xl">
            <div className="lg:w-1/2" ref={ref}>
              {(width < 1024 || ref.current) && contentfulTeam.youtubeID && (
                <YouTube
                  opts={{ width: width < 1024 ? String(width - 64) : String(columnWidth) }}
                  videoId={contentfulTeam.youtubeID}
                />
              )}
            </div>
            <div className="markdown-body px-6 mt-6 lg:w-1/2 lg:mt-0">
              {documentToReactComponents(contentfulTeam.body.json)}
              <div className="px-6 py-4">
                {contentfulTeam.tanzstile &&
                  contentfulTeam.tanzstile.map(stil => (
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={findCourse(stil)}
                      key={stil}
                      className="hide-after inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
                    >
                      {stil}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query($id: String!) {
    contentfulTeam(id: { eq: $id }) {
      name
      body {
        json
      }
      youtubeID
      tanzstile
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
